import axios, { serviceSuccessHandle } from './axios';

const getOrgInfo = async (orgUrl) => {
    return await axios({
        method: 'GET',
        url: 'store/web-get-org-info/' + orgUrl,
    }).then(serviceSuccessHandle);
}

const requestForOtp = async (orgUrl, mobile, isResend = 0,  isforWhatsapp = false, countryCode = null) => {
    return await axios({
        method: 'POST',
        url: `order/generate-otp-for-order-share-new/${orgUrl}/${isResend}/${mobile}`,
        data: {
            isforWhatsapp,
            country_code: countryCode
        }
    }).then(serviceSuccessHandle);
}

const verifyOtp = async (orgUrl, otp, mobile) => {
    return await axios({
        method: 'POST',
        url: `order/verify-otp-for-order-share-new/${orgUrl}`,
        data: {
            mobile,
            otp,
            is_store: 1
        }
    }).then(serviceSuccessHandle);
}

// Home Page Api
const topCategoryDesign = async (orgUrl, customer_id = "") => {
    return await axios({
        'method': 'POST',
        'url': `store/web-get-top-category-designs/${orgUrl}`,
        'data': {
            customer_id
        }
    }).then(serviceSuccessHandle);
}

const getCategorySomeDesign = async (orgUrl, customer_id = "") => {
    return await axios({
        'method': 'POST',
        'url': `store/web-get-category-and-some-designs/${orgUrl}`,
        'data': {
            customer_id
        }
    }).then(serviceSuccessHandle);
}

// Category Page Api 
const getCategories = async (pageInfo, orgUrl, catId = 0, searchString = "") => {
    return await axios({
        'method': 'POST',
        'url': pageInfo?.next ? pageInfo.next : `store/web-get-all-category/${orgUrl}/${catId}`,
        'data': {
            "customer_id": "",
            "search_string": searchString
        }
    }).then(serviceSuccessHandle);
}

// Products Page Api 
const getProducts = async (pageInfo, orgUrl, body) => {
    return await axios({
        'method': 'POST',
        'url': pageInfo?.next ? pageInfo.next : `store/web-get-all-designs/${orgUrl}`,
        'data': body
    }).then(serviceSuccessHandle);
}

// Update wishlist api
const saveWishlist = async (orgUrl, isActive, customer_id, design_id, customerCategoryId, isSavedWithQty = 0) => {
    const body = {
        'is_active': isActive ? 0 : 1,
        'design_info': [],
        customer_id,
        design_id,
        "is_cart_saved_with_qty": isSavedWithQty,
        'list_type': 0,
        customer_category_id: customerCategoryId
    }
    return await axios({
        'method': 'POST',
        'url': `store/set-design-cart-common/${customer_id}`,
        'data': body
    }).then(serviceSuccessHandle);
}

// Get Product Details 
const getProductDetails = async (orgUrl, productId, customer_id = "", categoryId = "", customerCategoryId) => {
    return await axios({
        'method': 'POST',
        'url': `store/web-get-product-info-common/${orgUrl}/${productId}`,
        'data': {
            categoryId,
            customer_id,
            customer_category_id: customerCategoryId
        }
    }).then(serviceSuccessHandle);
}


// Update Cart api
const saveCart = async (orgUrl, isActive, customer_id, design_id, design_info, list_type, is_cart_saved_without_qty = 0, customerCategoryId) => {
    const body = {
        'is_active': isActive ? 0 : 1,
        design_info,
        customer_id,
        design_id,
        list_type,
        is_cart_saved_with_qty: is_cart_saved_without_qty ? 0 : 1,
        customer_category_id: customerCategoryId
    }
    return await axios({
        'method': 'POST',
        'url': `store/set-design-cart-common/${customer_id}`,
        'data': body
    }).then(serviceSuccessHandle);
}

// Get count of bucket
const getCartTotal = async (orgUrl, customer_id, customerCategoryId) => {
    return await axios({
        'method': 'POST',
        'url': `store/get-cart-total-common/${customer_id}`,
        'data': {
            customer_id,
            customer_category_id: customerCategoryId
        }
    }).then(serviceSuccessHandle);
}


// SubCategory Api 
const getSubCategories = async (pageInfo, orgUrl, catId, customer_id) => {
    return await axios({
        'method': 'POST',
        'url': pageInfo?.next ? pageInfo.next : `store/web-get-all-category/${orgUrl}/${catId}`,
        'data': {
            customer_id
        }
    }).then(serviceSuccessHandle);
}

// SubCategory Api 
const getDesignCart = async (orgUrl, customer_id, customerCategoryId) => {
    return await axios({
        'method': 'POST',
        'url': `store/get-deisgn-cart-common/${customer_id}`,
        'data': {
            customer_id,
            customer_category_id: customerCategoryId
        }
    }).then(serviceSuccessHandle);
}

// getState 
const getState = async (stateName) => {
    return await axios({
        'method': 'GET',
        'url': `order/get-states?stateName=${stateName}`
    }).then(serviceSuccessHandle);
}

// getCity 
const getCity = async (stateId, cityName) => {
    return await axios({
        'method': 'GET',
        'url': `order/get-cities?cityName=${cityName}&stateId=${stateId}`,
    }).then(serviceSuccessHandle);
}

// userRegister
const userRegister = async (orgUrl, body) => {
    return await axios({
        'method': 'POST',
        'url': `order/create-customer/${orgUrl}`,
        'data': body
    }).then(serviceSuccessHandle);
}

// Place Order
const placeOrder = async (orgUrl, body) => {
    return await axios({
        'method': 'POST',
        'url': `order/place-order-new/${orgUrl}`,
        'data': body
    }).then(serviceSuccessHandle);
}

// Products Page Api 
const getOrgTags = async (pageInfo, body) => {
    return await axios({
        method: 'POST',
        url: pageInfo.page?.next ? pageInfo.page.next : `tag/get-store-parentorgwise-tag-list`,
        data: body
    }).then(serviceSuccessHandle);
}

const getOrgSizes = async (pageInfo, body) => {
    return await axios({
        'method': 'POST',
        'url': pageInfo.page?.next ? pageInfo.page.next : 'size/get-store-parentorgwise-size-list',
        'data': body
    }).then(serviceSuccessHandle);
}


const getOrgColors = async (pageInfo, body) => {
    return await axios({
        'method': 'POST',
        'url': pageInfo.page?.next ? pageInfo.page.next : 'color/get-store-parentorgwise-colors-list',
        'data': body
    }).then(serviceSuccessHandle);
}

const getOrgDesignMinMaxPrice = async (body) => {
    return await axios({
        'method': 'POST',
        'url': 'design/orgwise-designs-max-min-price-range-your-store',
        'data': body
    }).then(serviceSuccessHandle);
}
export {
    getOrgInfo,
    requestForOtp,
    verifyOtp,
    topCategoryDesign,
    getCategorySomeDesign,
    getCategories,
    getProducts,
    saveWishlist,
    getProductDetails,
    saveCart,
    getCartTotal,
    getSubCategories,
    getDesignCart,
    getState,
    getCity,
    userRegister,
    placeOrder,
    getOrgTags,
    getOrgSizes,
    getOrgColors,
    getOrgDesignMinMaxPrice
};