import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import _ from "lodash";
import AsyncSelect from 'react-select/async';
import { getCity, getState, userRegister } from "../../config/services";
import { toast } from 'react-toastify';



const RegisterModel = (props) => {
    const { currentLoginFlowStatus, setCurrentLoginFlowStatus, orgInfo, setCustomerInfo } = useContext(OrgInfoContext);
    const [errorNames, setErrorName] = useState([]);
    const [currentState, setCurrentState] = useState({});
    const [currentCity, setCurrentCity] = useState({});
    const requiredFields = ["customer_name", "contactPerson"];
    const getFormData = (event) => {
        let data = {};
        for (const e of event.target) {
            if (e?.name) {
                data[e.name] = e.value;
            }
        }
        return data;
    };
    const handleRegisterSubmit = async (event) => {
        event.preventDefault();
        const formData = getFormData(event);
        // console.log('Reg model >', formData)
        let currentErrorNames = [...errorNames];

        for (const rf of requiredFields) {
            if (!formData[rf]) {
                if (!currentErrorNames.includes(rf)) {
                    currentErrorNames.push(rf);
                    // setErrorName(currentErrorNames);
                }
                // return false;
            } else {
                currentErrorNames = currentErrorNames.filter(ce => ce !== rf);
                // setErrorName(currentErrorNames);
            }
        }

        // Set State And City
        formData["stateName"] = currentState?.label || "";
        formData["cityName"] = currentCity?.label || "";

        // Validate GST 
        if (formData["GSTNumber"]) {
            let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
            if (!regex.test(formData["GSTNumber"])) {
                currentErrorNames.push("GSTNumber");
            } else {
                currentErrorNames = currentErrorNames.filter(ce => ce !== "GSTNumber");
            }
        } else {
            currentErrorNames = currentErrorNames.filter(ce => ce !== "GSTNumber");
        }
        // console.log('Reg model >', formData)
        // console.log('currentErrorNames >', currentErrorNames)

        if (currentErrorNames.length) {
            setErrorName(currentErrorNames);
            return false;
        } else {
            setErrorName([]);
            formData.is_public = 1;
            formData.countryCode = "+91";
            formData.mobile = props.mobile;
            // console.log('formData >>> ', formData)
            const registeredUserData = await userRegister(orgInfo.store_url, formData);
            // console.log('[RegisterModel.js] registeredUserData >', registeredUserData)
            if (registeredUserData.status && registeredUserData?.data) {
                const custInfo = JSON.stringify(registeredUserData.data);
                window.sessionStorage.setItem(orgInfo.store_url, custInfo);
                setCustomerInfo(registeredUserData.data);
                setCurrentLoginFlowStatus(4);
                toast.success("You Are Registered Successfully");
            } else {
                if (!registeredUserData.status && registeredUserData?.error?.message) {
                    toast.warn(registeredUserData.error.message);
                } else {
                    toast.warn("Something Went Wrong Please Try Again");
                }
            }

        }


    };

    const getStates = async stateName => {
        // console.log('[RegisterModel.js] stateName >' + stateName)
        return await getState(stateName);
        // return await !stateName ? Promise.resolve([]) : getState(stateName);
    };

    const getStatesSelect = stateName => {
        // console.log('[RegisterModel.js] stateName >' + stateName)
        return getStates(stateName);
    };


    const removeSelectionCity = inputValue => {
        // console.log('inputValue >>> ', inputValue)
        setCurrentState(inputValue);
        setCurrentCity({});
    };

    const getCityApi = async (cityName = "") => {
        const stateId = currentState?.value || 0;
        return await !stateId ? Promise.resolve([]) : getCity(stateId, cityName);
    };
    const checkIfStateSelected = () => {
        const stateId = currentState?.value || 0;
        if (!stateId) {
            toast.warn('Please Select State First');
        }
    };
    const cityPromiseOptions = inputValue => {
        setCurrentCity(inputValue);
        return getCityApi(inputValue);
    };

    return (
        <Modal className="background-blurbx address-popup" show={currentLoginFlowStatus == 3} onHide={() => { }}>
            <div className="add-text-block address-wrp">
                <h3>Please Enter Following Details</h3>
                <form onSubmit={handleRegisterSubmit}>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <div className={"form-group " + (errorNames.includes(requiredFields[0]) ? "error" : "")}>
                                <label>Organization Name* :</label>
                                <input name="customer_name" type="text" placeholder="" className="form-control" />
                                <h6 className={"inputError " + (errorNames.includes(requiredFields[0]) ? "" : "d-none")}>
                                    Please enter valid Organization Name
                                </h6>

                            </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className={"form-group " + (errorNames.includes(requiredFields[1]) ? "error" : "")}>
                                <label>Your Name* :</label>
                                <input name="contactPerson" type="text" placeholder="" className="form-control" />
                                <h6 className={"inputError " + (errorNames.includes(requiredFields[1]) ? "" : "d-none")}>
                                    Please enter Valid Name
                                </h6>
                            </div>
                        </div>
                        <div className="col-md-12 col-sm-12">
                            <div className="form-group">
                                <label>Address :</label>
                                <input name="address" type="text" placeholder="" className="form-control" />
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>State :</label>
                                <AsyncSelect className="form-control selectBoxCss" placeholder="Select State" onChange={removeSelectionCity} value={currentState} name="stateName" cacheOptions defaultOptions loadOptions={getStatesSelect} />
                                {/* <select name="state" className="form-control">
                                    <option>Gujarat</option>
                                </select> */}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className="form-group">
                                <label>City :</label>
                                <AsyncSelect onFocus={checkIfStateSelected} className="form-control selectBoxCss" placeholder="Select City" onChange={setCurrentCity} value={currentCity} name="cityName" defaultOptions loadOptions={cityPromiseOptions} />
                                {/* <select name="city" className="form-control">
                                    <option>Ahmedabad</option>
                                </select> */}
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-12">
                            <div className={"form-group " + (errorNames.includes("GSTNumber") ? "error" : "")}>
                                <label>GST Number :</label>
                                <input name="GSTNumber" type="text" placeholder="" className="form-control" />
                                <h6 className={"inputError " + (errorNames.includes("GSTNumber") ? "" : "d-none")}>
                                    Please enter Valid GST
                                </h6>
                            </div>
                        </div>
                    </div>

                    <div className="add-row">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                {/* <div className="alter-msg-bx">
                                    <h6 className="error-msg"><i className="fal fa-exclamation-circle"></i> Please enter GST Number.</h6>
                                </div> */}
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="btn-group">
                                    <a onClick={() => {
                                        setCurrentLoginFlowStatus(1);
                                    }} className="btn-main canbtn">Cancel</a>
                                    <button type="submit" className="btn-main">Done</button>
                                </div>
                                <button type="submit" className="order-placed-open">Done</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>


    );
};

export default RegisterModel;