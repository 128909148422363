import React from "react";
import AppLayout from "../AppLayout";
import BucketInfo from "./BucketInfo";

const Bucket = () => {
    return (
        <AppLayout withoutSticky >
            <BucketInfo />
        </AppLayout>
    );
}

export default Bucket;