import React, { useContext } from "react";
import { placeHolderImages } from "../config/constant";
import OrgInfoContext from "../Contexts/OrgInfo.context";


const BucketWishListCard = ({ wishlistInfo, handleRemoveDesign }) => {
    const { setProductQtyModelDesId } = useContext(OrgInfoContext);
    return (
        <div className="order-block">
            <div className="row">
                <div className="col-md-8 col-sm-8">
                    <div className="order-box">
                        <img src={wishlistInfo.design_image} onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt="" />
                        <div className="order-dt">
                            <h2>{wishlistInfo.design_number}</h2>
                            {wishlistInfo.category_name ? <p>{wishlistInfo.categoryName}</p> : null}
                        </div>

                        <a onClick={(event) => handleRemoveDesign(wishlistInfo.design_id, 1)} className="cp">
                            <i className="fas fa-trash"></i>
                        </a>
                    </div>
                </div>
                <div className="col-md-4 col-sm-4 cp">
                    <a onClick={(event) => { setProductQtyModelDesId({ isFromMobile: 0, categoryId: wishlistInfo.category_id || wishlistInfo.child_category_id, categoryName: wishlistInfo.category_name, designName: wishlistInfo.design_number, designId: wishlistInfo.design_id }); }} className="log-btn addQty"> <i className="fas fa-plus-circle"></i> ADD QTY</a>
                    <a onClick={(event) => { setProductQtyModelDesId({ isFromMobile: 1, categoryId: wishlistInfo.category_id || wishlistInfo.child_category_id, categoryName: wishlistInfo.category_name, designName: wishlistInfo.design_number, designId: wishlistInfo.design_id }); }} className="log-btn addQty mobolbtn"> <i className="fas fa-plus-circle"></i> ADD QTY</a>
                </div>
            </div>
            {/* <div className="simmer-box"></div> */}
        </div>
    );
};

export default BucketWishListCard;