import React, { useContext } from "react";
import { placeHolderImages } from "../config/constant";
import OrgInfoContext from "../Contexts/OrgInfo.context";

const BucketAddedDesign = ({ desInfo, handleRemoveDesign, handleRemoveSizeFromDesign }) => {
    const desImage = <img src={desInfo.design_image} onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt="" />;
    const { setProductQtyModelDesId, customerInfo, orgInfo } = useContext(OrgInfoContext);
    return (
        <div className="item level-4">
            <div className="buket-box">
                <div className="buket-bix-header">
                    <div className="row">
                        <div className="col-md-8 col-sm-8">
                            <div className="buket-info">
                                {desInfo.list_type != 1 && desImage}
                                <h2>{desInfo.design_number}</h2>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="buket-ic">
                                <ul>
                                    <li className="cp">
                                        <a onClick={(event) => { setProductQtyModelDesId({ isFromMobile: 0, categoryId: desInfo.category_id || desInfo.child_category_id, designId: desInfo.design_id, categoryName: desInfo.category_name, }); }} className="bucketEditIcon"><i className="fas fa-pen"></i></a>
                                        <a onClick={(event) => { setProductQtyModelDesId({ isFromMobile: 1, categoryId: desInfo.category_id || desInfo.child_category_id, designId: desInfo.design_id, categoryName: desInfo.category_name, }); }} className="bucketEditIcon mobolbtn"><i className="fas fa-pen"></i></a>
                                    </li>
                                    {/* <li className="cp dot">
                                        <a ><i className="fas fa-file-alt"></i></a>
                                    </li> */}
                                    <li className="cp">
                                        <a onClick={() => handleRemoveDesign(desInfo.design_id, 0)}><i className="fas fa-trash"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="buket-tbl">
                    {desInfo.list_type != 1 &&
                        <div className="tbl-header">
                            <ul>
                                <li>{desInfo.list_type == 2 ? "COLOR" : desInfo.list_type == 3 ? "SIZE" : "COLOR / SIZE"}</li>
                                <li>QTY.</li>
                                <li>PRICE</li>
                                <li></li>
                            </ul>
                            <div className="clearfix"></div>
                        </div>
                    }
                    <div className="tbl-body">
                        {desInfo.design_info.map((di, ind) => (
                            <React.Fragment key={ind}>
                                {!di.color_wise_qty ? null : desInfo.list_type == 4 ? <h3>{di.color_name}</h3> : null}
                                {!di.color_wise_qty ? null : di.size_info.map((si, ins) => (
                                    !si.quantity ? null :
                                        <React.Fragment key={ins}>
                                            <ul>

                                                <li>{desInfo.list_type == 1 ? desImage : (desInfo.list_type == 4 || desInfo.list_type == 3) ? si.size_name : di.color_name}</li>
                                                <li>
                                                    {desInfo.list_type == 1 && <span>QTY.</span>}
                                                    {si.quantity}
                                                </li>
                                                <li>
                                                    {desInfo.list_type == 1 && <span>PRICE</span>}
                                                    <i className={orgInfo.store_json.show_rate ? (customerInfo.customerCategoryId && customerInfo.customerCategoryId != "") ? (si.size_rate ? "fal fa-rupee-sign mr-5" : "mr-5") : (si.size_rate || desInfo.sale_price) ? "fal fa-rupee-sign mr-5" : "mr-5" : "mr-5"}></i>{orgInfo.store_json.show_rate ? (customerInfo.customerCategoryId && customerInfo.customerCategoryId) != "" ? si.size_rate || "N/A" : si.size_rate || desInfo.sale_price || "N/A" : "N/A"}
                                                </li>
                                                {desInfo.list_type != 1
                                                    ?
                                                    <li className="cp">
                                                        <a onClick={() => handleRemoveSizeFromDesign(desInfo.design_id, ind, ins)}><i className="far fa-minus-circle text-red"></i></a>
                                                    </li>
                                                    :
                                                    null
                                                }
                                            </ul>
                                            <div className="clearfix"></div>
                                        </React.Fragment>
                                ))}
                            </React.Fragment>
                        ))}

                    </div>
                    <div className="total-box">
                        <h3>Total : {desInfo.total_qty}</h3>
                    </div>
                </div>
            </div>
            {/* <div className="simmer-box"></div> */}
        </div >
    );
};

export default BucketAddedDesign;