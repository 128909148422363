import React, { useContext, useEffect, useState } from "react";
import OrgInfoContext from "../Contexts/OrgInfo.context";

const Footer = () => {
    const { orgInfo } = useContext(OrgInfoContext);
    let socialLinks = [];
    if (orgInfo?.store_json?.social_links) {
        // console.log('[Footer.js] is in')
        for (let sl in orgInfo.store_json.social_links) {
            if (orgInfo.store_json.social_links[sl]) {
                socialLinks.push(
                    <li key={sl}><a target="_blank" href={orgInfo.store_json.social_links[sl]}><img src={"/images/icons/" + sl + ".svg"} alt="" /></a></li>
                );
            }
        }
    }
    return (
        <footer>
            <div className="container">
                <div className="footer-block">
                    <h3>STORE DETAILS</h3>
                    <h2>{orgInfo.organization_name}</h2>
                    <p>{orgInfo.address}</p>
                    <div className="footer-ic">
                        <ul className="pl-0">
                            {orgInfo.organization_contact_number && (<li><a href={"tel:" + orgInfo.organization_country_code + "" + orgInfo.organization_contact_number}><img src="/images/icons/phone.svg" alt="" /></a></li>)}
                            {orgInfo.whatsapp_url && (<li><a target="_blank" href={orgInfo.whatsapp_url}><img src="/images/icons/whatsapp.svg" alt="" /></a></li>)}
                            {socialLinks}
                            {/* <li><a target="_blank" href="#"><img src="/images/icons/instagram.svg" alt="" /></a></li>
                            <li><a target="_blank" href="#"><img src="/images/icons/facebook.svg" alt="" /></a></li>
                            <li><a target="_blank" href="#"><img src="/images/icons/youtube.svg" alt="" /></a></li> */}
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;