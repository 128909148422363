import React from "react";
const MainLoader = () => {
    return (
        <div className="overlay">
            <div className="overlayDoor"></div>
            <div className="overlayContent">
                <div className="loader">
                    <div className="inner"></div>
                </div>
            </div>
        </div>
    );
}

export default MainLoader;