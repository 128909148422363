import React, { useEffect } from "react";
import ProductListing from "./ProductListing";

const AllProducts = () => {

    useEffect(() => {
        document.title = "Products | " + document.title;

        return () => { };
    }, [])

    return (<ProductListing />);
}

export default AllProducts;