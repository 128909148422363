import React from "react";
import ContainerLine from "./ContainerLine";

const SearchBar = (props) => {
    const handleSearch = (event) => {
        event.preventDefault();
        // console.log('[SearchBar.js] event >', event)
        // console.log('[SearchBar.js] event target >', event.target[0].value)
        if (event.target && event.target.length) {
            props[props.searchFunctionName](event.target[0].value);
        }
    };
    return (
        <section style={{
            paddingTop: props?.isFromCategoryDetails ? "0px" : "130px"
        }} className="search-wrp">
            <div className="container-fluid">
                <div className="search-bx">
                    <form onSubmit={handleSearch}>
                        <div className="search-ic">
                            <i className="far fa-search"></i>
                        </div>
                        <input type="text" defaultValue={props?.currentSearchStr || ""} name="search" placeholder={props.placeHolder || "Search for a...."} className="form-control" />
                        <button type="submit"><i className="far fa-search"></i></button>
                    </form>
                </div>
            </div>

            <ContainerLine />
        </section>
    );
};

export default SearchBar;