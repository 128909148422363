import React, { useEffect, useContext, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import { placeHolderImages } from "../../config/constant";
import OrgInfoContext from "../../Contexts/OrgInfo.context";



import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app



const ProductImages = ({ images }) => {
    const { orgInfo } = useContext(OrgInfoContext);
    // console.log('[ProductImages.js] orgInfo >', orgInfo)
    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);


    // // Set CarouselData
    const carouselItems = images.map((img, index) => (
        <div className="item" key={index} >
            <div className="pro-dt-img">
                <img src={img} onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt="" />
                <a onClick={() => setIsOpen(true)} >
                    <i className="far fa-search-plus"></i>
                </a>
            </div>
        </div>
    ));
    return (
        <>
            <OwlCarousel className='owl-carousel owl-theme'
                autoplay={false}
                loop={false}
                stagePadding={0}
                autoplayTimeout={5000}
                autoplayHoverPause={false}
                smartSpeed={1500}
                nav={false}
                navText={false}
                dots={true}
                items={1}
                margin={0}
                onChanged={async (e) => {
                    // console.log('e.page.index >> ' + e.page.index)
                    // setTimeout(() => {
                    //     setPhotoIndex(parseInt(e.page.index, 10))
                    // }, 2000)
                }}
            >
                {carouselItems}
            </OwlCarousel>



            {isOpen && (
                <Lightbox
                    mainSrc={images[photoIndex]}
                    nextSrc={images[(photoIndex + 1) % images.length]}
                    prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                    onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                />
            )}




        </>
    );
};

export default ProductImages;