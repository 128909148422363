import React, { useEffect, useContext, useState } from "react";
import BucketLists from "./BucketLists";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import Wishlists from "./Wishlists";
import _ from "lodash";
import { getDesignCart, saveWishlist, saveCart, placeOrder } from "../../config/services";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import BucketLoader from "../../Components/Loaders/BucketLoader";
import { Modal } from "react-bootstrap";


const BucketInfo = () => {
    const { orgInfo, customerInfo, productCardActiveInfo, setProductCardActiveInfo, setFullBgBlur, setCustomerInfo } = useContext(OrgInfoContext);
    const [cartData, setCartData] = useState(null);
    const [expectedDate, setExpectedDate] = useState(null);
    const [mainNote, setMainNote] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isShowOrderSuccess, setIsShowOrderSuccess] = useState(false);
    const [orderedNumber, setOrderedNumber] = useState("");
    const [designDeleteInfo, setDesignDeleteInfo] = useState(null);
    const [designDeleteSizeInfo, setDesignDeleteSizeInfo] = useState(null);

    const dateObj = new Date();
    const month = String(dateObj.getMonth() + +1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    const currentDate = year + '-' + month + '-' + day;

    const history = useHistory();

    const apiGetDesignCart = async () => {
        setIsLoading(true);
        const apiCartData = await getDesignCart(orgInfo.store_url, customerInfo.pk_customerOrgID, customerInfo.customerCategoryId);
        setIsLoading(false);
        if (apiCartData.status) {
            apiCartData.data.total_amount = 0;
            apiCartData.data.total_setwise_qty = 0;
            apiCartData.data.total_qty = 0;
            for (const desInfo of apiCartData.data.design_data || []) {
                for (const colInfo of desInfo.design_info || []) {
                    for (const sizeInfo of colInfo.size_info || []) {
                        if (sizeInfo?.quantity) {
                            apiCartData.data.total_qty += parseInt(sizeInfo?.quantity || 0, 10);
                            apiCartData.data.total_amount += parseInt(desInfo?.set_value || 1, 10) * parseFloat(sizeInfo.quantity) * (parseFloat(sizeInfo?.size_rate) || parseFloat(desInfo?.sale_price));
                            apiCartData.data.total_setwise_qty += (parseInt(desInfo?.set_value || 1, 10) * parseFloat(sizeInfo.quantity));
                        }
                    }
                }
            }
            setCartData(apiCartData.data);
        }
    };

    useEffect(() => {
        // change the title
        document.title = "Cart | " + document.title;
        return () => { };
    }, []);

    useEffect(() => {
        // console.log('[BucketInfo.js] useEffect called productCardActiveInfo ', productCardActiveInfo);
        setCartData([]);
        apiGetDesignCart();

        return () => { };
    }, [productCardActiveInfo]);

    useEffect(() => {
        if (orderedNumber) {
            setIsShowOrderSuccess(true);
        } else {
            setIsShowOrderSuccess(false);
        }
    }, [orderedNumber]);

    // useEffect(() => {
    //     // console.log('[BucketInfo.js] useEffect designDeleteInfo executed designDeleteInfo >' , designDeleteInfo)
    //     if (designDeleteInfo !== null) {

    //     }
    // }, [designDeleteInfo])
    const dateInput = (
        <input min={currentDate} type="date" onInput={(event) => setExpectedDate(event.target.value)} className="form-control" placeholder="20 June 2018" />
    );

    const noteInput = (
        <textarea rows="1" placeholder="" onInput={(event) => setMainNote(event.target.value)} className="form-control"></textarea>
    );

    const removeDesign = () => async () => {
        try {
            // console.log('[BucketInfo.js] removeDesign designDeleteInfo >', designDeleteInfo);
            if (designDeleteInfo.designId) {
                const isSubmit = await saveWishlist(orgInfo.store_url, 1, customerInfo.pk_customerOrgID, designDeleteInfo.designId, customerInfo.customerCategoryId, parseInt(designDeleteInfo.isFromWishList) ? 0 : 1);
                // console.log('[BucketInfo.js] > isSubmit', isSubmit);
                setDesignDeleteInfo(null);
                if (isSubmit.status) {
                    if (isSubmit?.data?.message) {
                        setProductCardActiveInfo({
                            designId: designDeleteInfo.designId,
                            isActive: 0
                        });
                        toast.success(isSubmit.data.message);
                    }
                } else {
                    throw isSubmit;
                }
            }

        } catch (error) {
            console.error('[BucketInfo.js] > handleRemoveDesign error', error);
            // figCapEl.toggle("active");
        }
    };

    const handleRemoveDesign = (designId, isFromWishList = 0) => {
        setDesignDeleteInfo({ designId, isFromWishList });
    };

    const handleRemoveSizeFromDesign = (design_id, colorIndex, sizeIndex) => {
        // console.log('cartData >>> ', cartData);
        let listType = _.find(cartData.design_data, { design_id });
        if (listType) {
            listType = listType.list_type;
        } else {
            listType = 0;
        }
        setDesignDeleteSizeInfo({ design_id, colorIndex, sizeIndex, listType });
    };

    const removeDesignSize = () => async () => {
        try {
            if (designDeleteSizeInfo.design_id) {
                const currentCartData = _.cloneDeep(cartData);
                const desIndex = _.findIndex(currentCartData.design_data, { design_id: designDeleteSizeInfo.design_id });
                // console.log('desIndex ?>' + desIndex, designDeleteSizeInfo.colorIndex, designDeleteSizeInfo.sizeIndex);
                if (desIndex == -1) {
                    toast.warn("Woops, Something Went Wrong Please Try Again");
                    return false;
                }
                currentCartData.design_data[desIndex].design_info[designDeleteSizeInfo.colorIndex].size_info[designDeleteSizeInfo.sizeIndex].quantity = 0;
                let currentSum = 0;
                for (const ci of currentCartData.design_data[desIndex].design_info) {
                    if (currentSum) {
                        break;
                    }
                    for (const si of ci.size_info) {
                        if (si?.quantity) {
                            currentSum = 1;
                            break;
                        }
                    }
                }
                const isSubmit = await saveCart(orgInfo.store_url, 0, customerInfo.pk_customerOrgID, designDeleteSizeInfo.design_id, currentCartData.design_data[desIndex].design_info, currentCartData.design_data[desIndex].list_type, !currentSum, customerInfo.customerCategoryId);
                // console.log('[BucketInfo.js] > isSubmit', isSubmit);
                setDesignDeleteSizeInfo(null);
                if (isSubmit.status) {
                    if (isSubmit?.data?.message) {
                        setProductCardActiveInfo({
                            designId: designDeleteSizeInfo.design_id,
                            isActive: 1
                        });
                        toast.success(isSubmit.data.message);
                    }
                } else {
                    throw isSubmit;
                }
            }

        } catch (error) {
            console.error('[BucketInfo.js] > handleRemoveDesign error', error);
            // figCapEl.toggle("active");
        }
    };

    const submitData = async () => {

        // Check if date is valid or not
        if (expectedDate && (new Date(expectedDate) < new Date(currentDate))) {
            toast.warn("You Can not set Expected Delivery Date lesser then today.");
            return false;
        }

        const reqBody = {
            "delivery_date": expectedDate || "",
            "note": mainNote || "",
            "customer_org_id": customerInfo.pk_customerOrgID,
            "customer_country_code": "+91",
            "customer_mobile": customerInfo.mobile,
            "customer_name": !customerInfo.customer_name ? !customerInfo.contactPerson : customerInfo.customer_name,
            "design": []
        };



        if (cartData && cartData.design_data && cartData.design_data.length) {
            for (let desInfo of cartData.design_data || []) {
                for (let desColorInfo of desInfo.design_info || []) {
                    for (let desColorSizeInfo of desColorInfo.size_info || []) {
                        if (desColorSizeInfo.quantity > 0) {
                            reqBody.design.push({
                                "design_id": desInfo.design_id,
                                "color_id": desColorInfo.color_id,
                                "size_id": desColorSizeInfo.size_id,
                                "price": !desColorSizeInfo.size_rate ? !desInfo.sale_price ? 0 : desInfo.sale_price : desColorSizeInfo.size_rate,
                                "is_size": desInfo.list_type == 1 ? 0 : 1,
                                "quantity": desColorSizeInfo.quantity,
                                "item_index_no": reqBody.design.length + 1
                            });
                        }
                    }
                }
            }
        }
        // console.log('[BucketInfo.js] reqBody > ', reqBody);


        if (!reqBody.design.length) {
            toast.warn("There is no any design available in your cart. Please add at-list one design");
            return false;
        }

        const isOrderPlaced = await placeOrder(orgInfo.store_url, reqBody);
        if (isOrderPlaced.status) {
            setOrderedNumber(isOrderPlaced.data.order_number);
            setCartData([]);
            // Swal.fire(
            //     'Order Placed Successfully ...',
            //     'Your Order Number is ' + isOrderPlaced.data.order_number,
            //     'success'
            // );
            apiGetDesignCart();
        } else {
            if (isOrderPlaced.error && isOrderPlaced.error.code && isOrderPlaced.error.code == 160030) {
                window.sessionStorage.removeItem(orgInfo.store_url);
                setCustomerInfo(null);
                history.push('/' + orgInfo.store_url);
            }
        }

    };

    const submitDataHandler = () => () => {
        setFullBgBlur(true);
        Swal.fire({
            title: 'Are you sure?',
            text: 'You want to Place the order',
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: 'var(--blue)',
            confirmButtonText: 'Yes!',
            cancelButtonText: 'No',
            cancelButtonColor: 'var(--title)',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                submitData();
            }
            setFullBgBlur(false);
        });
    };
    return isLoading ?
        <BucketLoader />
        :
        (
            <>
                <section className="order-product-wrp tp100">
                    <div className="container">
                        {cartData?.design_data ?
                            <Wishlists
                                bucketData={cartData.design_data.filter(d => !d.is_cart_saved_with_qty)}
                                handleRemoveDesign={handleRemoveDesign}
                            />
                            :
                            null
                        }

                        <BucketLists
                            dateInput={dateInput}
                            noteInput={noteInput}
                            bucketData={!cartData?.design_data ? [] : cartData.design_data.filter(d => d.is_cart_saved_with_qty)}
                            handleRemoveDesign={handleRemoveDesign}
                            handleRemoveSizeFromDesign={handleRemoveSizeFromDesign}
                            cartData={cartData}
                            submitData={submitDataHandler}
                            orgStoreJson={orgInfo.store_json}
                        />

                    </div>
                </section>
                {/* Order Success Popup */}
                <Modal className="background-blurbx order-placed-popup" show={orderedNumber && isShowOrderSuccess} onHide={() => { setOrderedNumber(""); }}>
                    <div className="add-text-block order-placed-wrp">
                        <img src="/images/order-placed-img.svg" alt="" />

                        <h4>Order Placed Successfully</h4>
                        <p>Your Placed Order Number is {orderedNumber}</p>

                        <a onClick={() => {
                            setOrderedNumber("");
                            history.push('/' + orgInfo.store_url);
                        }} className="cp btn-main">explore more</a>

                        <div className="download-app">

                            <h6>For Track This Order Download The <strong>VastraApp</strong></h6>
                            <div className="d-flex text-center justify-content-center">
                                <a style={{
                                    marginRight: "1%"
                                }} target="_blank" href="https://play.google.com/store/apps/details?id=com.vastra"><img src="/images/gp.png" alt="" /></a>
                                <a target="_blank" href="https://itunes.apple.com/kw/app/vastra-app/id1429785393?mt=8"><img src="/images/ap.png" alt="" /></a>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="background-blurbx delete-popup" show={designDeleteInfo !== null} >
                    <div class="add-text-block">
                        <img src="/images/trash-img.svg" alt="" />
                        <h4>Are you sure want to Delete Design ?</h4>

                        <div class="cart-actionbar trash-btns">
                            <button onClick={() => {
                                setDesignDeleteInfo(null);
                            }} class="btn-main calbtn" data-dismiss="modal">NO</button>
                            <button onClick={removeDesign()} class="btn-main">YES</button>
                        </div>
                    </div>
                </Modal>

                <Modal className="background-blurbx delete-popup" show={designDeleteSizeInfo !== null} >
                    <div class="add-text-block">
                        <img src="/images/trash-img.svg" alt="" />
                        <h4>Are you sure want to Remove this {designDeleteSizeInfo?.listType == 4 || designDeleteSizeInfo?.listType == 3 ? 'Size' : designDeleteSizeInfo?.listType == 2 ? 'Color' : 'Particular'} from the Design ?</h4>

                        <div class="cart-actionbar trash-btns">
                            <button onClick={() => {
                                setDesignDeleteSizeInfo(null);
                            }} class="btn-main calbtn" data-dismiss="modal">NO</button>
                            <button onClick={removeDesignSize()} class="btn-main">YES</button>
                        </div>
                    </div>
                </Modal>

            </>
        );
};

export default BucketInfo;