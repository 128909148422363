import React from "react";
import AppLayout from "../AppLayout";
import ProductInfo from "./ProductInfo";
const ProductDetails = (props) => {
    // console.log('[ProductDetails.js] props >', props);
    return (
        <AppLayout withoutSticky>
            <ProductInfo {...props} />
        </AppLayout>
    );
};

export default ProductDetails;