import React from "react";
import BucketAddedDesign from "../../Components/BucketAddedDesign";

const BucketLists = ({ submitData, handleRemoveSizeFromDesign, handleRemoveDesign, bucketData, dateInput, noteInput, cartData, orgStoreJson }) => {
    // console.log('[BucketLists.js] cartData >', cartData)
    const bucketCards = bucketData.map((bd, ind) => (
        <BucketAddedDesign handleRemoveSizeFromDesign={handleRemoveSizeFromDesign} desInfo={bd} key={ind} handleRemoveDesign={handleRemoveDesign} />
    ));
    return bucketData && bucketData.length ? <>
        <div className="buket-wrp">
            <div className="titlebx">
                <h2>Bucket {bucketData.length ? <span>{bucketData.length}</span> : null}</h2>
            </div>
            <div className="clearfix"></div>

            <div className="buket-gallery">
                <div className="buket-grid-block">
                    {bucketCards}
                    <div className="item plase-mobile-box">
                        <div className="buket-box">
                            <div className="form-group">
                                <label>Expected Delivery Date</label>
                                {dateInput}
                            </div>
                            <div className="form-group last-group">
                                <label>Add Note Here</label>
                                {noteInput}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="clearfix"></div>
        </div>
        {/* Section for Desktop View*/}
        <section className="place-fix">
            <div className="container">
                <form>
                    <div className="row">
                        <div className="col-md-6 col-sm-12 left-brd">
                            <div className="form-group">
                                <label>Expected Delivery Date</label>
                                {dateInput}
                                {/* <input type="date" className="form-control" placeholder="20 June 2018" /> */}
                            </div>
                            <div className="form-group last-group">
                                <label>Add Note Here</label>
                                {noteInput}
                                {/* <textarea rows="1" placeholder="" className="form-control"></textarea> */}
                            </div>
                        </div>

                        <div className="col-md-6 col-sm-12">
                            <div className="total-bx">
                                <ul className="pl-0">
                                    <li>Total Qty. <span>{cartData?.total_setwise_qty}</span></li>
                                    {
                                        cartData?.total_qty !== cartData?.total_setwise_qty ? <li>Total Set. <span>{cartData?.total_qty}</span></li> : null

                                    }
                                    <li>Total Amt. <span>{orgStoreJson.show_rate && cartData?.total_amount}</span></li>
                                    <li>
                                        <a onClick={submitData()} >PLACE ORDER</a>
                                    </li>
                                </ul>
                                <a onClick={submitData()} className="cp modal-mobile">PLACE ORDER</a>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {/* <div className="simmer-box"></div> */}
        </section>

    </>
        :
        cartData?.design_data?.length
            ?
            null
            :
            <div className="buket-wrp">

                <div className="clearfix"></div>
                <div className="cart-not-found-bx">
                    <img src="/images/cart-not.svg" alt="" />
                    <h3>Bucket is Empty</h3>
                    <p>Checkout trending products and add them to this bucket</p>
                </div>
            </div>;
};

export default BucketLists;