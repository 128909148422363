import React from "react";
import BannerSliders from "../../Components/BannerSliders";
import Footer from "../../Components/Footer";
import CategoryWiseDesigns from "./CategoryWiseDesigns/CategoryWiseDesigns";
import AppLayout from "../AppLayout";
// import NewArrivalTopCategory from "./NewArrivalTopCategory/NewArrivalTopCategory";
import CategoriesList from "../Categories/CategoriesList";

const Home = () => {

    // changeColor()

    return (
        <AppLayout>
            {/* <BannerSliders />
            <NewArrivalTopCategory />
            <CategoryWiseDesigns />
            <Footer /> */}
            <CategoriesList />
            <Footer />
        </AppLayout>
    );
}

export default Home;