import React, { useEffect, useContext, useState } from "react";
import { useParams } from 'react-router-dom';
import ProductListing from "../Products/ProductListing";
import SubCategoryListing from "./SubCategoryListing";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import { getSubCategories } from "../../config/services";
import CategoryDetailsLoader from "../../Components/Loaders/CategoryDetailsLoader";
import MainContext from "../../Contexts/Main.context";

// import $ from 'jquery';

const CategoryInfo = (props) => {
    const { orgInfo, customerInfo } = useContext(OrgInfoContext);
    const { categoryDetailsPageInfo, setCategoryDetailsPageInfo } = useContext(MainContext);
    const [subCategories, setSubCategories] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    // const [checkIsMore, setCheckIsMore] = useState(0);
    const [childCategoryId, setChildCategoryId] = useState(0);
    // const [activeCategoryId, setActiveCategoryId] = useState(0);
    const [subCategoryName, setSubCategoryName] = useState("All Products");
    const [isLoading, setIsLoading] = useState(true);

    // when user refresh the page
    const urlParams = useParams();
    const queryParams = new URLSearchParams(window.location.search);
    const categoryName = decodeURIComponent(queryParams.get('category'));

    const apiGetSubCategories = async () => {
        // console.log('[CategoryInfo.js] getAllSubCategory called')
        const getAllSubCategory = await getSubCategories(pageInfo, orgInfo.store_url, props?.location?.categoryId || urlParams.categoryId || categoryDetailsPageInfo.categoryId , customerInfo?.pk_customerOrgID || "");
        setIsLoading(false);
        // console.log('[CategoryInfo.js] getAllSubCategory > ', getAllSubCategory)
        if (getAllSubCategory.status) {
            let currentSubCategories = JSON.parse(JSON.stringify(subCategories));
            if (!currentSubCategories.length && getAllSubCategory.data.length) {
                currentSubCategories = [
                    {
                        category_id: props.location.categoryId,
                        title: "All Products",
                        isActive: true
                    }
                ];
            }
            const loadMoreCard = {
                category_id: props.location.categoryId,
                title: "Show More",
                isActive: 0,
                isLoadMore: true
            };
            currentSubCategories = currentSubCategories.filter(csc => !csc.isLoadMore ? true : false);
            if (getAllSubCategory?.page?.next) {
                getAllSubCategory.data.push(loadMoreCard);
            }
            // console.log("[CategoryInfo.js] apiGetSubCategory data > ", [...currentSubCategories, ...getAllSubCategory.data]);
            setSubCategories([...currentSubCategories, ...getAllSubCategory.data]);
            setPageInfo(getAllSubCategory?.page || {});
            setIsLoadingMore(false);
        }
    };

    // useEffect(() => {
    //     // console.log('[CategoryInfo.js] useEffect checkIsMore >', checkIsMore)

    //     return () => { };
    // }, [checkIsMore])
    const handleShowMore = () => {
        // console.log('[CategoryInfo.js] handleShowMore Called')
        // console.log('[CategoryInfo.js] pageInfo >', pageInfo)
        // // console.log('[CategoryInfo.js] handleShowMore checkIsMore >', checkIsMore)
        // setCheckIsMore(checkIsMore + 1);
        if (pageInfo?.next) {
            apiGetSubCategories();
        } else {
            let currentSubCategories = JSON.parse(JSON.stringify(subCategories));
            currentSubCategories = currentSubCategories.filter(csc => !csc.isLoadMore ? true : false);
            setSubCategories(currentSubCategories);
        }
    };
    useEffect(() => {
        // console.log('[CategoryInfo.js] props >', props)
        if (!categoryDetailsPageInfo?.categoryId || !props?.location?.categoryId) {
            // props && props.history.push("/" + orgInfo.store_url);
            // setCategoryDetailsPageInfo({
            //     categoryId: urlParams.categoryId,
            //     categoryName: categoryName
            // })

            props.location.categoryId = urlParams.categoryId;
            props.location.categoryName = categoryName;
        }

        if(0 &&queryParams.get('subid')) {
            // console.log('[useEffect-------------------]');
            const subCategoryIdFromURL = decodeURIComponent(queryParams.get('subid')); // sub category id
            const subCategoryNameFromURL = decodeURIComponent(queryParams.get('subname')); // su category name
            // console.log('subCategoryIdURL', subCategoryIdFromURL);
            // console.log('subCategoryNameURL', subCategoryNameFromURL);

            setSubCategoryName(subCategoryNameFromURL);
            setChildCategoryId(subCategoryIdFromURL);
        }
      

        document.title = (categoryDetailsPageInfo?.categoryName || props?.location?.categoryName || "All Products") + " | " + document.title;
        setSubCategoryName(categoryDetailsPageInfo?.categoryName || props?.location?.categoryName || "All Products");
        apiGetSubCategories();


        // $(function () {
        //     // console.log("ready!");
        //     // $('.choice-bx').addClass("on-page");
        //     // $('.choice-bx').on("click", (event) => {
        //     //     // console.log('choice-bx Called >', event);
        //     //     $(event.currentTarget).addClass("on-page");
        //     // })
        // });
        return () => { };
    }, []);
    const selectCategoryHandle = (catId) => {
        // console.log('[CategoryInfo.js] selectCategoryHandle called>', catId)
        let currentSubCategories = JSON.parse(JSON.stringify(subCategories)).map(css => {
            css.isActive = catId == css.category_id;
            if (css.isActive) {
                setSubCategoryName(css.category_name);

                const queryParamsObj = new URLSearchParams(window.location.search);
                queryParamsObj.set('subid', encodeURIComponent(catId));
                queryParamsObj.set('subname', encodeURIComponent(css.category_name)); // su category name

                // update url without refresh
                const updatedUrl = window.location.pathname + "?" + queryParamsObj.toString();
                window.history.pushState({ path: updatedUrl }, '', updatedUrl);
                
            }
            return css;
        });
        if (!catId) {
            setSubCategoryName("All Products");
        }
        // setActiveCategoryId(catId)
        setChildCategoryId(catId);
        setSubCategories(currentSubCategories);
    };

    return <>
        {
            isLoading ?
                <CategoryDetailsLoader />
                :
                subCategories.length ?
                    <SubCategoryListing
                        selectCategoryHandle={selectCategoryHandle}
                        pageInfo={pageInfo}
                        handleShowMore={handleShowMore}
                        subCatData={subCategories}
                    />
                    :
                    null
        }
        <section className="category-wrp product-listing-wrp">
            <div className="container-fluid">
                <ProductListing subCategoryName={subCategoryName} category_id={props?.location?.categoryId || urlParams.categoryId || categoryDetailsPageInfo?.categoryId || 0} child_category_id={childCategoryId || urlParams.subid} isFromCategoryDetails={subCategories?.length} />
            </div>
        </section>
    </>;
};

export default CategoryInfo;