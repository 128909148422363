import React, { useEffect, useState, useContext } from "react";
import { placeHolderImages } from "../config/constant";
import { saveWishlist } from "../config/services";
import OrgInfoContext from "../Contexts/OrgInfo.context";
import { toast } from 'react-toastify';
import { Link } from "react-router-dom";

const ProductCard = ({ productInfo }) => {
    const { setProductCardActiveInfo, productCardActiveInfo, orgInfo, customerInfo, setCurrentLoginFlowStatus, setProductQtyModelDesId, } = useContext(OrgInfoContext);
    const [is_saved_design, set_is_saved_design] = useState(productInfo?.is_saved_design || 0);
    const [designId, setDesignId] = useState(productInfo?.design_id || 0);
    // console.log('[ProductCard.js] > customerInfo', customerInfo);
    useEffect(() => {
        if (designId === productCardActiveInfo?.designId) {
            if (productCardActiveInfo?.isActive) {
                set_is_saved_design(1);
            } else {
                set_is_saved_design(0);
            }
        }
        return () => { };
    }, [productCardActiveInfo]);
    const wishListHandler = async (event, designId) => {
        if (!customerInfo) {
            // console.log('[ProductCard.js] wishListHandler customerInfo >', customerInfo);
            setCurrentLoginFlowStatus(1);
            return false;
        }
        // console.log('[ProductCard.js] > wishListHandler designId', designId);

        // const figCapEl = event.target.closest('figcaption').classList;
        // figCapEl.toggle("active");
        // // Check if active then in active
        try {
            const isSubmit = await saveWishlist(orgInfo.store_url, is_saved_design, customerInfo.pk_customerOrgID, designId, customerInfo.customerCategoryId);
            // console.log('[ProductCard.js] > isSubmit', isSubmit);
            if (isSubmit.status) {
                if (isSubmit?.data?.message) {
                    set_is_saved_design(!is_saved_design);
                    setProductCardActiveInfo({
                        designId,
                        isActive: !is_saved_design
                    });
                    toast.success(isSubmit.data.message);
                }
            } else {
                throw isSubmit;
            }
        } catch (error) {
            console.error('[ProductCard.js] > wishListHandler error', error);
            // figCapEl.toggle("active");
        }
    };

    const saveQtyHandler = async (event, designInfo) => {
        if (!customerInfo) {
            // console.log('[ProductCard.js] saveQtyHandler customerInfo >', customerInfo);
            setCurrentLoginFlowStatus(1);
            return false;
        }
        // console.log('[ProductCard.js] > saveQtyHandler designInfo', designInfo);
        setProductQtyModelDesId(designInfo);
    };
    const wishlistButton = (designId) => (<span><a onClick={(event) => wishListHandler(event, designId)} className="heart cp"><i className="fas fa-heart"></i></a></span>);
    const addEditButtons = (designInfo) => (
        <>
            <a onClick={(event) => saveQtyHandler(event, designInfo)} className="cp log-btn">
                <i className={["fas", is_saved_design ? "fa-pencil-alt" : "fa-plus-circle"].join(" ")}></i>
                <span>{is_saved_design ? "EDIT" : "ADD"} QTY</span>
            </a>
            <a onClick={(event) => saveQtyHandler(event, { ...designInfo, isFromMobile: 1 })} className="cp log-btn mobolbtn">
                <i className={["fas", is_saved_design ? "fa-pencil-alt" : "fa-plus-circle"].join(" ")}></i>
                <span>{is_saved_design ? "EDIT" : "ADD"} QTY</span>
            </a>
        </>);
    const categoryId = productInfo.category_id || productInfo.child_category_id || 0;
    const desToDetails = {
        pathname: `/${orgInfo.store_url}/product-details/${categoryId}/${productInfo.design_id}`,
        categoryId: categoryId,
        designId: productInfo.design_id
    };
    return (
        <figure>
            <Link to={desToDetails}>
                <div className="mini-bx">
                    <img src={productInfo.image} onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.DESIGN; }} alt="" />
                </div>
            </Link>
            <figcaption className={"figcap-desid-" + productInfo.design_id + " " + (customerInfo && is_saved_design ? "active" : null)}>
                <h2>
                    <Link to={desToDetails}>
                        <b className={productInfo.isDetailed ? "link-prd" : ""}>
                            {productInfo.design_number || productInfo.design_name}
                        </b>
                    </Link>
                    {productInfo.isDetailed ? wishlistButton(productInfo.design_id) : ""}
                </h2>

                <p className={productInfo.isDetailed ? "m-0" : ""}>{productInfo.category_name || ""}</p>

                {productInfo.isDetailed ?
                    <div className="pro-pribxs">
                        {orgInfo.store_json.show_rate ? <h5>₹ {productInfo.design_price || '-'}</h5> : <h5>&nbsp;</h5>}
                        {addEditButtons({ isFromMobile: 0, categoryId: productInfo.category_id || productInfo.child_category_id, categoryName: productInfo.category_name, designName: productInfo.design_name, designId: productInfo.design_id })}
                    </div>
                    :
                    null
                }
                {!productInfo.isDetailed ? addEditButtons({ isFromMobile: 0, categoryId: productInfo.category_id || productInfo.child_category_id, categoryName: productInfo.category_name, designName: productInfo.design_name, designId: productInfo.design_id }) : null}
                {!productInfo.isDetailed ? wishlistButton(productInfo.design_id) : null}
            </figcaption>
            {/* <div className="simmer-box"></div> */}
        </figure>
    );
};

export default ProductCard;