import React from "react";
import { placeHolderImages } from "../config/constant";

const SubCategoryCard = ({ /* setActiveCategoryId, */ subCateInfo, handleShowMore, selectCategoryHandle, cardOnClickHandler }) => {
    // console.log('subCateInfo ?>>> ' + JSON.stringify(subCateInfo));
    return (
        <li className={(subCateInfo?.title ? "choice-lastbix" : "") + " cp choice-bx " + (subCateInfo?.isActive ? " on-page " : "")}>
            <a title={subCateInfo.category_name} onClick={() => cardOnClickHandler(subCateInfo)}>
                <div className="chbx-bx">
                    {subCateInfo?.title ? <h3>{subCateInfo.title}</h3>
                        :
                        <>
                            <div className="choice-img">
                                <img src={subCateInfo.image} alt="" onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.CATEGORY; }} />
                            </div>
                            <h4>{subCateInfo.category_name}</h4>
                        </>
                    }
                    {/* <div className="simmer-box"></div> */}
                </div>
            </a>
        </li>
    );
};

export default SubCategoryCard;