import React from "react";
import AppLayout from "../AppLayout";
import CategoriesList from "./CategoriesList";

const Categories = () => {
    return (
        <AppLayout>

            <CategoriesList />

        </AppLayout>
    );
}

export default Categories;