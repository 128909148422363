import axios from 'axios';
import { apiConf } from './constant';

function getUDID() {
    return window.navigator.userAgent.replace(/\D+/g, '');
}

function getDeviceType() {
    let sys = {
        "ie": "",
        "firefox": "",
        "chrome": "",
        "opera": "",
        "safari": ""
    };
    const ua = navigator.userAgent.toLowerCase();
    let s;

    let temp = (s = ua.match(/msie ([\d.]+)/)) ? sys.ie = s[1] :
        (s = ua.match(/firefox\/([\d.]+)/)) ? sys.firefox = s[1] :
            (s = ua.match(/chrome\/([\d.]+)/)) ? sys.chrome = s[1] :
                (s = ua.match(/opera.([\d.]+)/)) ? sys.opera = s[1] :
                    (s = ua.match(/version\/([\d.]+).*safari/)) ? sys.safari = s[1] : 0

    if (sys.ie) return ('IE: ' + sys.ie);
    if (sys.firefox) return ('Firefox: ' + sys.firefox);
    if (sys.chrome) return ('Chrome: ' + sys.chrome);
    if (sys.opera) return ('Opera: ' + sys.opera);
    if (sys.safari) return ('Safari: ' + sys.safari);
    temp = "";
    return "web" + temp;
}

/** For Development */
const developement = axios.create({
    baseURL: apiConf.developmentBaseUrl
});
developement.defaults.headers.common['api-key'] = apiConf.developmentApiKey;

/** For Production */
const production = axios.create({
    baseURL: apiConf.productionBaseUrl
});
production.defaults.headers.common['api-key'] = apiConf.productionApiKey;

const instance = process.env.NODE_ENV === 'development' ? developement : production;
instance.defaults.headers.common['UDID'] = getUDID();
instance.defaults.headers.common['device-type'] = getDeviceType();


export const serviceSuccessHandle = async (res) => {
    if (res.status < 300) {
        return res.data;
    }
}

export default instance;