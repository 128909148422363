import React from "react";
import AppLayout from "../AppLayout";
import CategoryInfo from "./CategoryInfo";

const CategoryDetails = (props) => {
    // console.log('[CategoryDetails.js] props >', props);
    return (
        <AppLayout>
            <CategoryInfo {...props} />
        </AppLayout>
    );
};

export default CategoryDetails;