import React from "react";

const NotFound = () => {
    return (
        <div className="page-notfound">
            <div className="center-pager">
                <img src="/images/icons/404.svg" alt="" />

                <div className="error-txt">
                    <h3>Whoops!</h3>
                    <p>We couldn't find the page you were looking for</p>

                    {/* <a href="/" className="btn-main">GO TO HOME</a> */}
                </div>
            </div>
        </div>
    )
}

export default NotFound;