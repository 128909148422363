window.$ = window.jQuery = require('jquery');

/* **** Header **** */
window.jQuery(window).scroll(function () {
    if (window.jQuery(this).scrollTop() > 150) {
        window.jQuery("header").addClass("nav-new");
    } else {
        window.jQuery("header").removeClass("nav-new");
    }
});
/* *** ENd Header **** */


/* ** Responsive Nav js ** */
window.jQuery('.mobolbtn').on('click', function () {
    window.jQuery('.add-clbx').addClass('mobile-menu-open');
});

window.jQuery('.calbtn').on('click', function () {
    window.jQuery('.add-clbx').removeClass('mobile-menu-open');
});



/* Responsive Nav js **/
window.jQuery(".modal-mobile").on("click", function () {
    window.jQuery(".add-clbx").toggleClass("mobile-menu-open");
});

window.jQuery(".back-modal-mobile").on("click", function () {
    window.jQuery(".add-second").toggleClass("mobile-menu-open");
    window.jQuery(".add-clbx").toggleClass("mobile-menu-open");
});


/* Responsive Nav js **/
window.jQuery(".btn-otp-side").on("click", function () {
    window.jQuery(".add-clbx").removeClass("mobile-menu-open");
    window.jQuery(".add-second").addClass("mobile-menu-open");
});
