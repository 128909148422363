import React from "react";
import MainLoader from "../MainLoader";
const BucketLoader = () => {
    return (
        <MainLoader />
        // <div style={{
        //     display: "inline-flex"
        // }}>
        //     <div className="slider-arrivalbx">
        //         <figure style={{
        //             height: "350px",
        //             width: "230px",
        //             marginLeft: "2em",
        //         }}>
        //             <div style={
        //                 {
        //                     width: "230px",
        //                     height: "350px",
        //                 }
        //             } className="simmer-box"></div>
        //         </figure>
        //     </div >

        // </div>
    )
}

export default BucketLoader;