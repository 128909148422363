import React, { useContext, useEffect, useState } from "react";
import { NavLink, withRouter, useParams, useLocation } from 'react-router-dom';
import { getCartTotal } from "../config/services";
import OrgInfoContext from "../Contexts/OrgInfo.context";


const StickeyMenu = () => {
    const { storeName } = useParams();
    const location = useLocation();
    const [badge, setBadge] = useState(0);
    const { productCardActiveInfo, customerInfo } = useContext(OrgInfoContext);
    useEffect(async () => {
        // console.log('[StickeyMenu.js] useEffect called customerInfo ', customerInfo)
        if (customerInfo && customerInfo?.pk_customerOrgID) {
            try {
                const getCount = await getCartTotal(storeName, customerInfo.pk_customerOrgID);
                if (getCount.status) {
                    setBadge(getCount?.data?.save_design_count || 0);
                }
            } catch (error) {
                console.error('[StickeyMenu.js] Execption error', error);
            }
        } else {
            setBadge(0);
        }
        return () => { };
    }, [productCardActiveInfo, customerInfo]);

    const getNavLinkClass = (path) => {
        let classInfo = location.pathname === path ? 'active' : '';
        if (location.pathname.split('/').pop() == "category-details" && path.split('/').pop() == storeName) {
            classInfo = 'active';
        }
        return classInfo;
    };

    return (

        <div className="bottom-nav">
            <ul className="mb-0 p-0">
                <li className={getNavLinkClass("/" + storeName)}>
                    <NavLink to={"/" + storeName} exact>
                        {/* <img src="/images/icons/home.svg" alt="" />
                        <span>Home</span> */}
                        <img src="/images/icons/ic_more.svg" alt="" />
                        <span>Category</span>
                    </NavLink>
                </li>
                {/* <li className={getNavLinkClass("/" + storeName + "/categories")}>
                    <NavLink to={"/" + storeName + "/categories"} exact>
                        <img src="/images/icons/ic_more.svg" alt="" />
                        <span>Category</span>
                    </NavLink>
                </li> */}
                <li className={getNavLinkClass("/" + storeName + "/products")}>
                    <NavLink to={"/" + storeName + "/products"} exact>
                        <img src="/images/icons/ic_design.svg" alt="" />
                        <span>Products</span>
                    </NavLink>
                </li>
                <li className={getNavLinkClass("/" + storeName + "/bucket")}>
                    <NavLink to={"/" + storeName + "/bucket"} exact>
                        <img src="/images/icons/shopping-cart.svg" alt="" />
                        <span>Bucket</span>
                        {badge ? <span className="badge">{badge}</span> : null}

                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default withRouter(StickeyMenu);

