import React, { useContext, useState } from 'react';
import { requestForOtp, verifyOtp, requestForOtpWhatssApp } from '../../config/services';
import OrgInfoContext from '../../Contexts/OrgInfo.context';
import LoginModel from './LoginModel';
import OtpModel from './OtpModel';
import Swal from 'sweetalert2';
import RegisterModel from './RegisterModel';


const AuthFlow = () => {
    const { orgInfo, setCurrentLoginFlowStatus, setCustomerInfo } = useContext(OrgInfoContext);
    // const [loggedInPopup, setLoggedInPopup] = useState(false);

    const [mobile, setMobile] = useState("");
    const [mobileError, setMobileError] = useState("");
    // handle otp
    const [otp, setOtp] = useState("");
    const [otpError, setOtpError] = useState("");
    const [coutryCode, setCountryCode] = useState("");
    const [isSendToWhatsApp, setIsSendToWhatsApp] = useState(false);

    // Current Status
    // const [currentLoginFlowStatus, setCurrentLoginFlowStatus] = useState(1);
    // // Api Calls
    const APIrequestForOtp = async (isResend = 0) => {
        try {
            // console.log('[AuthFlow.js] orgInfo > ', orgInfo)
            let resOfReqOtp = { 
                    status: false,
                    data: {
                        message : ''
                    }
                };

            if(isSendToWhatsApp) {
                requestForOtp(orgInfo.store_url, mobile, isResend, isSendToWhatsApp, coutryCode);
                setCurrentLoginFlowStatus(2);
            } else {
                resOfReqOtp = await requestForOtp(orgInfo.store_url, mobile, isResend, false, null);
            }
          
            // console.log('[AuthFlow.js] resOfReqOtp >' + JSON.stringify(resOfReqOtp));
            // Handle True
            if (resOfReqOtp.status) {
                const lastFiveChar = resOfReqOtp.data.message.substr(resOfReqOtp.data.message.length - 5);
                if (!isNaN(parseInt(lastFiveChar))) {
                    setOtp(lastFiveChar);
                }
                setCurrentLoginFlowStatus(2);
            } else {
                if (resOfReqOtp.data && resOfReqOtp.data.message) {
                    setMobileError(resOfReqOtp.data.message);
                }
            }
        } catch (error) {
            setMobileError("Something Went Wrong. Please Try Again");
        }
    };

    const APIverifyOtp = async () => {
        try {

            const resOfVerifyOtp = await verifyOtp(orgInfo.store_url, otp, mobile);
            // console.log('[AuthFlow.js] resOfVerifyOtp >' + JSON.stringify(resOfVerifyOtp));

            // Handle True
            if (resOfVerifyOtp.status) {
                if (resOfVerifyOtp?.data?.customerInfo) {
                    // SetDataInSession(resOfVerifyOtp.data.customerInfo);
                    const custInfo = JSON.stringify(resOfVerifyOtp.data.customerInfo);
                    window.sessionStorage.setItem(orgInfo.store_url, custInfo);
                    setCustomerInfo(resOfVerifyOtp.data.customerInfo);
                    setCurrentLoginFlowStatus(4);
                }
                // Check and handle Registration
                else {
                    // Private
                    if (!orgInfo.is_public) {
                        Swal.fire('Oops...', 'You are not register as customer of ' + orgInfo.organization_name, 'error');
                    }
                    // public
                    else {
                        setCurrentLoginFlowStatus(3);
                    }
                }
            } else {
                if (resOfVerifyOtp.error && resOfVerifyOtp.error.message) {
                    setOtpError(resOfVerifyOtp.error.message);
                } else {
                    throw (resOfVerifyOtp);
                }
            }
        } catch (error) {
            console.error('error >> ', error);
            setOtpError("Something Went Wrong. Please Try Again");
        }
    };

    const handleMobileSubmit = async (isResend = 0) => {
        // console.log('[AuthFlow.js] mobile > ' + mobile)
        APIrequestForOtp(isResend);
    };

    const handleResendOtp = () => {
        APIrequestForOtp(1);
    };

    const handleOtpSubmit = () => {
        APIverifyOtp();
    };

    const loginFlow = (
        <>
            <LoginModel mobile={mobile} setMobile={setMobile} setCountryCode={setCountryCode} setIsSendToWhatsApp={setIsSendToWhatsApp} handleMobileSubmit={handleMobileSubmit} mobileError={mobileError} setMobileError={setMobileError} />
            <OtpModel handleOtpSubmit={handleOtpSubmit} handleResendOtp={handleResendOtp} mobile={mobile} otp={otp} setOtp={setOtp} otpError={otpError} setOtpError={setOtpError} />
            <RegisterModel mobile={mobile} />
        </>
    );
    return loginFlow;
};


export default AuthFlow;