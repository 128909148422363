import React,{ useCallback } from "react";
import { debounce } from "lodash";
const SearchBarProductFilter = (props) => {

    const handleSearch = useCallback(
        debounce((searchTerm) => {
            console.log('handleSearch debounce ---',searchTerm);
            props.searchHandle(searchTerm);
            }, 300, {trailing : true}
        ),[]
    ); 

    // const handleSearch = ()=>{ 
    //     props.searchHandle();
    // }
     


    const handleChange = (event) => {
        // event.persist();
        console.log('handleSearch SearchBarProductFilter--- nkkkk',event.target.value);
        props.setData({data: []});
        props.setCurrentSearchStr(event.target.value);
        handleSearch(event.target.value)
    };

    return (
        <section style={{
            // paddingTop: props?.isFromCategoryDetails ? "0px" : "130px"
        }} className="">
            <div className="" style={{ marginBottom: '5px' }}>
                <div className="">
                    <form>
                        <input 
                            ref={props.inputRef}
                            type="text" 
                            value={props.currentSearchStr}
                            name="search" placeholder={props.placeHolder || "Search for a...."} 
                            className="form-control" 
                            onChange={handleChange}
                            onClick={props.inputClickHandler}
                        />
                    </form>
                </div>
            </div>
        </section>
    );
};

export default SearchBarProductFilter;