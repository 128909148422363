import React, { useEffect, useContext, useState } from "react";
import { getCartTotal, getProductDetails, saveWishlist } from "../../config/services";
import OrgInfoContext from "../../Contexts/OrgInfo.context";
import { toast } from 'react-toastify';
import ProductImages from "./ProductImages";
import { Link } from "react-router-dom";
import ProductDetailsLoader from "../../Components/Loaders/ProductDetailsLoader";

const ProductInfo = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [productInfo, setProductInfo] = useState(null);
    const [isOpenSubDetails, setIsOpenSubDetails] = useState(false);
    const [is_saved_design, set_is_saved_design] = useState(0);
    const [badge, setBadge] = useState(0);
    const { orgInfo, customerInfo, setProductQtyModelDesId, setCurrentLoginFlowStatus, productCardActiveInfo, setProductCardActiveInfo } = useContext(OrgInfoContext);
    const [noteScroll, setNoteScroll] = useState(false);
    useEffect(async () => {
        // console.log('[ProductInfo.js] UseEffect Called orgInfo', props);

        if (!props?.location?.designId) {
            // get url params
            props.location.designId = props?.match?.params?.designId;
            props.location.categoryId = Number(props?.match?.params?.categoryId);
            // props && props.history.push("/" + orgInfo.store_url);
        }
        const getProductInfo = await getProductDetails(orgInfo.store_url, props.location.designId, customerInfo && customerInfo.pk_customerOrgID ? customerInfo.pk_customerOrgID : "", props?.location?.categoryId || 0, customerInfo && customerInfo.customerCategoryId ? customerInfo.customerCategoryId : "");
        // console.log('[ProductInfo.js] getProductInfo', getProductInfo);
        setIsLoading(false);
        if (getProductInfo.status) {
            document.title = getProductInfo.data.design_number + " | " + document.title;
            if (customerInfo && customerInfo.customerCategoryId && customerInfo.customerCategoryId != "" && getProductInfo.data) {
                if (getProductInfo.data.color_size_data.length && getProductInfo.data.color_size_data[0].size_info.length
                    && getProductInfo.data.color_size_data[0].size_info[0].size_rate && getProductInfo.data.color_size_data[0].size_info.find(sd => sd.size_id == "0")) {
                    getProductInfo.data.sale_price = getProductInfo.data.color_size_data[0].size_info[0].size_rate;
                } else {
                    getProductInfo.data.sale_price = 0;
                }
            }
            setProductInfo(getProductInfo.data);
            set_is_saved_design(getProductInfo.data?.is_saved_design);
        }
        setDescriptionNotesScroll();
        return () => { };
    }, []);
    useEffect(() => {
        if (props?.location?.designId === productCardActiveInfo?.designId) {
            if (productCardActiveInfo?.isActive) {
                set_is_saved_design(1);
            } else {
                set_is_saved_design(0);
            }
        }
        return () => { };
    }, [productCardActiveInfo]);
    useEffect(async () => {
        // console.log('[ProductInfo.js] useEffect called customerInfo ', customerInfo)
        if (customerInfo && customerInfo?.pk_customerOrgID) {
            try {
                const getCount = await getCartTotal(orgInfo.store_url, customerInfo.pk_customerOrgID, customerInfo.customerCategoryId);
                if (getCount.status) {
                    setBadge(getCount?.data?.save_design_count || 0);
                }
            } catch (error) {
                console.error('[ProductInfo.js] Execption error', error);
            }
        } else {
            setBadge(0);
        }
        return () => { };
    }, [is_saved_design]);
    // const productInfo = { "design_id": "e67170078d68bedf_1587987536359", "design_number": "DES-Koi Pagal Samjta Hai", "sale_price": 694, "design_notes": "Amezing casual design", "is_size": 0, "set_value": 1, "store_info": { "canRestrictByStock": 0 }, "design_images": ["https://images.webstaging.vastraapp.com/design/2629_4642_e67170078d68bedf_1607519066856_ncolhj3q.jpg", "https://images.webstaging.vastraapp.com/design/2629_4642_e67170078d68bedf_1607519025166_c5o0rzew.jpg", "images/"], "list_type": 1, "color_size_data": [{ "is_virtually_added": 1, "color_id": "701", "color_name": "No Color", "size_info": [{ "size_id": "0", "size_name": "Default", "is_virtually_added": 1, "size_rate": 0, "is_stock_available": 1, "quantity": 0 }] }], "is_stock_available": 1, "all_tags": "Casual", "categories": "Summer Wear, Others, Cargo Pent", "total_qty": 0, "is_saved_design": 0 };
    // // console.log('[ProductInfo.js] props >', props);
    const saveQtyHandler = async (event, designInfo) => {
        if (!customerInfo) {
            setCurrentLoginFlowStatus(1);
            return false;
        }
        // console.log('[ProductInfo.js] > saveQtyHandler designInfo', designInfo);
        setProductQtyModelDesId(designInfo);
    };

    const wishListHandler = async (event, designId) => {
        if (!customerInfo) {
            setCurrentLoginFlowStatus(1);
            return false;
        }
        // console.log('[ProductInfo.js] > wishListHandler designId', designId);
        // // Check if active then in active
        try {
            const isSubmit = await saveWishlist(orgInfo.store_url, is_saved_design, customerInfo.pk_customerOrgID, designId, customerInfo.customerCategoryId);
            // console.log('[ProductInfo.js] > isSubmit', isSubmit);
            if (isSubmit.status) {
                if (isSubmit?.data?.message) {
                    set_is_saved_design(!is_saved_design);
                    setProductCardActiveInfo({
                        designId,
                        isActive: !is_saved_design
                    });
                    toast.success(isSubmit.data.message);
                }
            } else {
                throw isSubmit;
            }
        } catch (error) {
            console.error('[ProductInfo.js] > wishListHandler error', error);
            // figCapEl.toggle("active");
        }
    };

    const setDescriptionNotesScroll = () => {
        let domElement = document.getElementById('product-note-p');
        let scrollHeight = domElement && domElement.scrollHeight ? domElement.scrollHeight : null;
        if (scrollHeight && scrollHeight > 399) {
            setNoteScroll(true);
        }
    }
    return isLoading ?
        <ProductDetailsLoader />
        : !productInfo ?
            <ProductDetailsLoader />
            :
            <section className="products-details-wrp main-product-wrp tp100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 col-md-12 col-sm-12">
                            <div className="products-details-slider">
                                <ProductImages images={productInfo?.design_images} />
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12 col-sm-12">
                            <div className={"product-details-bottom " + (isOpenSubDetails ? "mobile-menu-open" : "")}>
                                <div className="pro-details-box product-dt-box">
                                    <div className="box-shadow-box">
                                        <a onClick={() => setIsOpenSubDetails(!isOpenSubDetails)} className="open-product"></a>
                                        <div className="pro-dt-hed">
                                            <h3>{productInfo?.design_number}</h3>
                                            {orgInfo.store_json.show_rate ? <h4>₹ {productInfo?.sale_price || '-'}</h4> : <h4>&nbsp;</h4>}
                                            <a onClick={(event) => saveQtyHandler(event, { isFromMobile: 0, categoryId: props?.location?.categoryId || 0, designName: productInfo.design_number, designId: productInfo.design_id })} className={"log-btn " + (is_saved_design ? "active" : "")} tabIndex="0">
                                                <i className={["fas", is_saved_design ? "fa-pencil-alt" : "fa-plus-circle"].join(" ")}></i>
                                                {is_saved_design ? "EDIT" : "ADD"} QTY
                                            </a>
                                            <a onClick={(event) => saveQtyHandler(event, { isFromMobile: 1, categoryId: props?.location?.categoryId || 0, designName: productInfo.design_number, designId: productInfo.design_id })} className={"mobolbtn log-btn " + (is_saved_design ? "active" : "")} tabIndex="0">
                                                <i className={["fas", is_saved_design ? "fa-pencil-alt" : "fa-plus-circle"].join(" ")}></i>
                                                {is_saved_design ? "EDIT" : "ADD"} QTY
                                            </a>
                                            <div className="quick-act-pro">
                                                <ul>
                                                    <li>
                                                        <Link to={{
                                                            pathname: "/" + orgInfo.store_url + "/bucket"
                                                        }} className="cp" >
                                                            <i className="fas fa-shopping-cart"></i>
                                                            {badge ? <span>{badge}</span> : null}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a onClick={(event) => wishListHandler(event, productInfo.design_id)}>
                                                            <i className={"heart cp fas fa-heart " + (is_saved_design ? "active" : "")}></i>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className="pro-lst-bx default-product-info-snackbar">
                                            <ul className="pl-0">
                                                <li>Categories <span>{productInfo?.categories}</span></li>
                                                {orgInfo.store_json.show_tag && productInfo?.all_tags ? <li>Tags <span>{productInfo?.all_tags}</span></li> : null}
                                                {productInfo?.set_value > 1 ? <li>1 Set = <span>{productInfo.set_value} PCS</span></li> : null}
                                            </ul>
                                            {productInfo?.design_notes ?
                                                <div className="note-bx">
                                                    <h4>Notes</h4>
                                                    <p id="product-note-p" className={`whiteSpacePreLine ${noteScroll ? 'productInfo-notes' : ''}`}>{`${productInfo.design_notes}`}</p>
                                                </div>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >;

};

export default ProductInfo;