import React from "react";
import AppLayout from "../AppLayout";
import AllProducts from "./AllProducts";

const Products = () => {

    return (
        <AppLayout>

            <AllProducts />

        </AppLayout>
    );
}

export default Products;