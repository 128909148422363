import React, { Suspense } from "react";
import { Route, Switch } from 'react-router-dom';
import NotFound from "./pages/NotFound";

// const Home = React.lazy(() => import('./pages/Home/Home'));
// const Categories = React.lazy(() => import('./pages/Categories/Categories'));
// const Products = React.lazy(() => import('./pages/Products/Products'));
// const Bucket = React.lazy(() => import('./pages/Bucket/Bucket'));
// const CategoryDetails = React.lazy(() => import('./pages/CategoryDetails/CategoryDetails'));
// const ProductDetails = React.lazy(() => import('./pages/ProductDetails/ProductDetails'));
import Home from './pages/Home/Home';
import Categories from './pages/Categories/Categories';
import Products from './pages/Products/Products';
import Bucket from './pages/Bucket/Bucket';
import CategoryDetails from './pages/CategoryDetails/CategoryDetails';
import ProductDetails from './pages/ProductDetails/ProductDetails';


const Routes = () => {
  return (
    // <Suspense fallback={<React.Fragment />}>
    <Switch>
      <Route path="/:storeName/categories" exact component={Categories} />
      <Route path="/404" component={NotFound} exact />
      <Route path="/:storeName/" exact component={Home} />


      <Route path="/:storeName/products" exact component={Products} />

      <Route path="/:storeName/bucket" exact component={Bucket} />

      <Route path="/:storeName/category-details/:categoryId" exact component={CategoryDetails} />

      <Route path="/:storeName/product-details/:categoryId/:designId" exact component={ProductDetails} />

      <Route component={NotFound} exact />
    </Switch>
    // </Suspense>
  )
};


export default Routes;
