import React, { useState, useContext } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { navWithBackButton, placeHolderImages } from "../config/constant";
import { Modal } from "react-bootstrap";
import OrgInfoContext from "../Contexts/OrgInfo.context";
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';


const Header = ({ history, withBackButton }) => {
    const location = useLocation();
    let isBackButton = false;

    const { setFullBgBlur, setCustomerInfo, orgInfo, customerInfo, setCurrentLoginFlowStatus } = useContext(OrgInfoContext);
    // console.log('[Header.js] > orgInfo', orgInfo);
    // console.log('[Header.js] > customerInfo', customerInfo);

    const currentUrl = location.pathname.split('/').pop();
    if (currentUrl) {
        isBackButton = navWithBackButton.includes(currentUrl);
    }

    if (withBackButton) {
        isBackButton = true;
    }

    const handleLogout = () => {
        // console.log('[Header.js] > handleLogout Called');
        setFullBgBlur(true);
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: 'You want to logout',
                icon: 'question',
                showCancelButton: true,
                allowOutsideClick: false,
                confirmButtonColor: 'var(--red)',
                confirmButtonText: 'Yes!',
                cancelButtonText: 'No',
                cancelButtonColor: 'var(--title)',
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    window.sessionStorage.removeItem(orgInfo.store_url);
                    setCustomerInfo(null);
                    toast.success('Logged Out Successfully', {
                        onClose: (event) => {
                            // console.log('[Header.js] handleLogout toast success event >', event)
                            history.push('/' + orgInfo.store_url);
                        },
                        autoClose: 1500
                    });
                }
                setFullBgBlur(false);
            });
        } catch (error) {
            console.error('[Header.js] > excecption handleLogout error', error);
            toast.warn('Something Went Wrong, Please try again.');
            setFullBgBlur(false);
        }
    };

    const handleLogin = () => {
        // console.log('[Header.js] > handleLogin Called');
        setCurrentLoginFlowStatus(1);
    };

    return (
        <header>
            {/* Register Model */}
            <div className={isBackButton ? "container" : "container-fluid"}>
                <div className="header-row">
                    <div className="head-left">
                        {isBackButton && <a onClick={history.goBack} className="btn_back cp"><i className="far fa-chevron-left"></i></a>}
                        <a className="user-img">
                            <img src={orgInfo.organization_logo} alt="" onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.ORGANIZATION_LOGO; }} />
                        </a>
                        <a className="cp" onClick={() => history.push("/" + orgInfo.store_url)}><h1 className="ml-1 mobileWidthChange">{orgInfo.organization_name || "Your Store"}</h1></a>
                    </div>
                    <div className="head-right">
                        <ul>
                            <li>
                                <a onClick={customerInfo ? handleLogout : handleLogin} className="log-btn cp">
                                    <img src={"/images/icons/" + (customerInfo ? "logout" : "login") + "-ic.svg"} alt="" />
                                    <span>{customerInfo ? 'LogOut' : 'LogIn'}</span>
                                </a>
                            </li>
                            <li>
                                {/* <a className="user-img">
                                    <img src={orgInfo.organization_logo} alt="" onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.ORGANIZATION_LOGO }} />
                                </a> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default withRouter(Header);