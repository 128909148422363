import { useParams } from 'react-router-dom';


export const CheckIsLoggedin = () => {
    const { storeName } = useParams();
    // console.log('[common.js] storeName >' + storeName);
    return window.sessionStorage.getItem(storeName) ? true : false;
};

export const SetDataInSession = (data) => {
    const { storeName } = useParams();
    window.sessionStorage.setItem(storeName, data);
    return true;
};

export const isTouchDevice = () => {
    return typeof window.ontouchstart !== 'undefined';
};

export const disableScroll = () => {
    // console.log('Disable Scroll');
    document.body.style.overflow = 'hidden';
    return false;
};

export const enableScroll = () => {
    // console.log('Enable Scroll');
    // console.log('document.body ??', document.body);
    document.body.style.overflow = 'auto';
    // document.body.removeAttribute("style");
    return true;
};