import React, { useState, useEffect, useContext } from "react";
import { Modal } from "react-bootstrap";
import OtpInputs from 'react-otp-input';
import { isTouchDevice } from "../../common";
import OrgInfoContext from "../../Contexts/OrgInfo.context";



const OtpModel = (props) => {
    const { currentLoginFlowStatus, setCurrentLoginFlowStatus } = useContext(OrgInfoContext);

    // Start Interval
    const [currentCount, setCount] = useState(40);
    const timer = () => setCount(currentCount - 1);

    useEffect(
        () => {
            if (currentLoginFlowStatus == 2) {
                // console.log('[OtpModel.js] Use Effect Called ')
                if (currentCount <= 0) {
                    return;
                }
                const id = setInterval(timer, 1000);
                return () => clearInterval(id);
            }
            return () => { setCount(40); };
        },
        [currentCount, currentLoginFlowStatus]
    );

    const handleOtpSubmit = (event) => {
        event.preventDefault();
        if (/^\d{5}$/.test(props.otp.toString())) {
            props.setOtpError("");
            props.handleOtpSubmit();
        } else {
            props.setOtpError("Please Enter Valid 5 Digit Number");
        }
    };

    const otpData = (
        <div className="add-text-block otp-wrp">
            <form onSubmit={handleOtpSubmit}>
                <div className="otp-head">
                    <div className="back-step cp">
                        <a onClick={() => {
                            setCurrentLoginFlowStatus(1);
                            props.setOtp("");
                            props.setOtpError("");
                        }
                        }><i className="far fa-angle-left"></i></a>
                    </div>
                    <div className="count-down-bx">
                        <div id="timer" data-minutes="0" data-seconds="40"></div>
                    </div>
                </div>
                <img src="/images/otp-mobile.svg" alt="" />

                <h4>Enter OTP</h4>
                <p>Please enter OTP to confirm your Mobile Number.</p>
                <h3>{props.mobile}</h3>

                <h5>OTP</h5>

                <div className="mobile-num-bx">

                    <OtpInputs className="w-100 otp-div"
                        onChange={(otp) => {
                            // console.log('otp > ' + otp);
                            props.setOtpError("");
                            props.setOtp(otp);
                        }}
                        value={props.otp}
                        numInputs={5}
                        inputStyle="form-control"
                        isInputNum
                        shouldAutoFocus={false}
                    />
                    <h6 className="error-msg">
                        {props.otpError || ""}
                    </h6>
                </div>

                <div className="cart-actionbar trash-btns">
                    <button disabled={currentCount ? true : false} onClick={() => {
                        // console.log('Disabled On click')
                        props.handleResendOtp();
                        setCount(40);
                    }} className="btn-main calbtn">RESEND OTP {currentCount ? "IN (" + currentCount + ")" : ""}</button>
                    <button type="submit" className="btn-main" onClick={() => {
                        // handleCloseOTPModel();
                    }} >VERIFY NOW</button>
                </div>
            </form >
        </div >
    );
    return (
        <>
            {
                isTouchDevice() ?
                    <>
                        <div className={((currentLoginFlowStatus == 2) ? "mobile-menu-open" : "") + " back-blur-fx add-clbx"}></div>
                        <div className={((currentLoginFlowStatus == 2) ? "mobile-menu-open" : "") + " modal-content add-clbx mobilecart-aside"}>
                            {otpData}
                        </div>
                    </>
                    :
                    < Modal className="background-blurbx otp-popup" onHide={() => { }} show={currentLoginFlowStatus == 2} >
                        {otpData}
                    </Modal >
            }
        </>
    );
};

export default OtpModel;