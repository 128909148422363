import React from "react";
import { PropTypes } from "prop-types";
import { Dropdown } from "react-bootstrap";
const ProductFilter = (props) => {
    // // console.log('Props.filter', filter)
    const handleOnSelect = (eventKey, event) => {
        props[props.functionName](eventKey);
    };
    return (
        <div className="col-md-5 col-sm-5">
            <div className="filter-bx">
                {/* <div className="dropdown">
                    <button type="button" className="btn dropdown-toggle" data-toggle="dropdown">
                        <i className="fas fa-filter"></i>
                    </button>
                    <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Law to High</a>
                        <a className="dropdown-item" href="#">High to Law</a>
                        <a className="dropdown-item" href="#">New to Old</a>
                        <a className="dropdown-item" href="#">Old to New</a>
                    </div>
                </div> */}
                <Dropdown onSelect={handleOnSelect}>
                    <Dropdown.Toggle className="btn dropdown-toggle" id="dropdown-basic">
                        <i className="fas fa-filter"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="right-0 mt-5">
                        {props.dropDownData.map((dd, ind) => (
                            <Dropdown.Item className={(dd.isActive ? 'active' : '') + (!ind ? ' top-border ' : '')} key={dd.id} eventKey={dd.id}>{dd.name}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        </div>
    );
};


ProductFilter.propTypes = {
    dropDownData: PropTypes.array.isRequired,
};

ProductFilter.defaultProps = {
    dropDownData: []
};
export default ProductFilter;