import React, { useEffect } from "react";
import { placeHolderImages } from "../config/constant";
import { Link, NavLink } from 'react-router-dom';
import { useContext } from "react";
import OrgInfoContext from "../Contexts/OrgInfo.context";
import MainContext from "../Contexts/Main.context";

const CategoryCard = ({ categoryInfo }) => {
    const { orgInfo } = useContext(OrgInfoContext);
    const { setCategoryDetailsPageInfo } = useContext(MainContext);
    // console.log('[CategoryCard.js] categoryInfo', categoryInfo)
    const catToDetails = {
        pathname: `/${orgInfo.store_url}/category-details/${categoryInfo.category_id}?category=${categoryInfo.category_name}`,
        categoryId: categoryInfo.category_id,
        categoryName: categoryInfo.category_name
    };
    return (
        <Link to={catToDetails} onClick={() => {
            setCategoryDetailsPageInfo({
                categoryId: categoryInfo.category_id,
                categoryName: categoryInfo.category_name
            })
        }}
            exact>
            <figure className="categoryCard">
                <div className="mini-bx cp">
                    <img src={categoryInfo.category_image || categoryInfo.image} onError={(e) => { e.target.onerror = null; e.target.src = placeHolderImages.CATEGORY; }} alt="" />
                </div>
                <figcaption>
                    <h2>
                        <b>{categoryInfo.category_name}</b>
                    </h2>
                </figcaption>
                {/* <div className="simmer-box"></div> */}
            </figure>
        </Link>
    );
};

export default CategoryCard;