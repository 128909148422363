import { createContext, useState, useEffect } from 'react';

// Create Context
const MainContext = createContext(null);
export default MainContext;

export const MainContextProvider = (props) => {
    const [categoryDetailsPageInfo, setCategoryDetailsPageInfo] = useState(null);


    useEffect(() => {
        // Store the state in localStorage whenever it changes
        localStorage.setItem('categoryDetailsPageInfo', JSON.stringify(categoryDetailsPageInfo));
    }, [categoryDetailsPageInfo]);

    return (
        <MainContext.Provider value={{ categoryDetailsPageInfo, setCategoryDetailsPageInfo }}>
            {props.children}
        </MainContext.Provider>
    );
};
