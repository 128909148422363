import React, { useContext, useState } from "react";
import { Accordion, Card, useAccordionToggle, AccordionContext } from "react-bootstrap";
import BucketWishListCard from "../../Components/BucketWishListCard";

const CustomToggle = ({ children, eventKey, callback }) => {
    const activeEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () =>
        callback && callback(eventKey),
    );
    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <h3 onClick={decoratedOnClick} className={"mb-0 " + (isCurrentEventKey ? "collapsed" : "")}>
            {children}
        </h3>
    );
}
const Wishlists = (props) => {
    const wishlistCards = props.bucketData.map((wd, ind) => {
        return (
            <div key={ind} className="col-md-6 col-sm-12 col-lg-6 col-xl-4">
                <BucketWishListCard handleRemoveDesign={props.handleRemoveDesign} wishlistInfo={wd} />
            </div>
        );
    })
    return props.bucketData.length ?
        <div className="wishlist">

            <Accordion defaultActiveKey="0">
                <Card>
                    <Card.Header className="card-head wishlist-accordian-header">
                        {/* <div className="card-head" id="headingOne"> */}
                        <CustomToggle eventKey="0">
                            Wishlist {props.bucketData.length ? <span>{props.bucketData.length}</span> : null}
                        </CustomToggle>
                        {/* </div> */}
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" >
                                <div className="card-body">
                                    <div className="row">
                                        {wishlistCards}
                                    </div>
                                </div>
                            </div>
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>

            </Accordion>

        </div>
        :
        null
        ;

}

export default Wishlists;