import React from "react";

const ContainerLine = () => {
    return (
        <div className="atc-box">
            <div className="container-fluid">
                <hr />
            </div>
        </div>
    );

}

export default ContainerLine;